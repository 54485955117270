import { useMutation, gql } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useNotifications } from "../../../components/Notification";

export default function RestoreCompanyDialog({
  companyId,
  handleClose,
  refetch,
}: RestoreCompanyDialogProps) {
  const [restoreCompanyMutation] = useMutation<
    RestoreCompanyMutationResponse,
    RestoreCompanyMutationInput
  >(RESTORE_COMPANY_MUTATION);
  const { showNotification } = useNotifications();

  const restoreCompany = () =>
    restoreCompanyMutation({
      variables: {
        input: {
          companyId: companyId!,
        },
      },
    })
      .then((res) => {
        res.data?.restoreCompany.success
          ? showNotification({
              message: `company restored`,
              severity: "success",
            })
          : showNotification({
              message: res.data?.restoreCompany.error!,
              severity: "error",
            });
      })
      .then(() => refetch());

  return (
    <Dialog open={companyId !== null} onClose={handleClose}>
      <DialogTitle>{"Restore company?"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you wish to restore this company?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            restoreCompany();
            handleClose();
          }}
        >
          Restore Company
        </Button>
        <Button onClick={handleClose} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const RESTORE_COMPANY_MUTATION = gql`
  mutation RestoreCompanyMutation($input: RestoreCompanyInput!) {
    restoreCompany(input: $input) {
      success
      error
    }
  }
`;

type RestoreCompanyMutationInput = {
  input: {
    companyId: string;
    deleteVenues?: boolean;
  };
};

type RestoreCompanyMutationResponse = {
  restoreCompany: {
    success: boolean;
    error?: string;
  };
};

interface RestoreCompanyDialogProps {
  handleClose: () => void;
  companyId: string | null;
  refetch: () => void;
}
