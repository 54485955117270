import { gql, useMutation } from "@apollo/client";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import { utcToZonedTime, format } from "date-fns-tz";
import { useState } from "react";

import Button from "../../../../components/Button2";
import Checkbox from "../../../../components/Checkbox";
import MediaUploader, { MediaType } from "../../../../components/MediaUploader";
import { useNotifications } from "../../../../components/Notification";
import TextField from "../../../../components/TextField";
import { Location } from "../../../../types";

import "./form.css";
import AutocompleteAreaSelector from "../../../../components/AutocompleteAreaSelector";

export default function EditLocationForm(props: { location: Location }) {
  const { showNotification } = useNotifications();

  const [nameEn, setNameEn] = useState(props.location.nameEn);
  const [nameJa, setNameJa] = useState(props.location.nameJa);
  const [slug, setSlug] = useState(props.location.slug);
  const [pageTitle, setPageTitle] = useState(props.location.pageTitle);
  const [pageDescription, setPageDescription] = useState(
    props.location.pageDescription
  );
  const [thumbnailImageUrl, setThumbnailImageUrl] = useState<string | null>(
    props.location.thumbnailImageUrl
  );
  const [imageUrl, setImageUrl] = useState<string | null>(
    props.location.imageUrl
  );
  const [videoUrl, setVideoUrl] = useState<string | null>(
    props.location.videoUrl
  );
  const [isHomeContent, setIsHomeContent] = useState<boolean>(
    props.location.isHomeContent
  );
  const [homePriority, setHomePriority] = useState<number | null>(
    props.location.homePriority
  );
  const [includeArea, setIncludeArea] = useState<Location[]>(
    props.location.includeArea
  );
  const [editLocation] = useMutation(EDIT_LOCATION_MUTATION);

  return (
    <>
      <Grid style={{ marginBottom: "80px" }} container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card style={{ width: "100%" }}>
            <CardContent>
              <TextField
                style={{ marginTop: "0px" }}
                label="ID"
                value={props.location.id}
                disabled={true}
              />
              <TextField
                label="Created At"
                disabled={true}
                value={format(
                  utcToZonedTime(
                    Number(props.location.createdAt),
                    "Asia/Tokyo"
                  ),
                  "yyyy-MM-dd HH:mm:ss"
                )}
              />
              <TextField
                label="Type"
                disabled={true}
                value={props.location.type}
              />
              <TextField
                label="English Name"
                required
                value={nameEn}
                onChange={(e: any) => setNameEn(e.target.value)}
              />
              <TextField
                label="Japanese Name"
                required
                value={nameJa}
                onChange={(e: any) => setNameJa(e.target.value)}
              />
              {props.location.parent && (
                <TextField
                  label="Parent Location"
                  disabled={true}
                  value={`${props.location.parent.nameEn} (${props.location.parent.nameJa})`}
                />
              )}
              <TextField
                label="Slug"
                value={slug}
                onChange={(e: any) => setSlug(e.target.value)}
              />
              <TextField
                label="Page Title"
                value={pageTitle}
                onChange={(e: any) => setPageTitle(e.target.value)}
              />
              <TextField
                multiline={true}
                label="Page Description"
                value={pageDescription}
                onChange={(e: any) => setPageDescription(e.target.value)}
              />
              <Checkbox
                style={{ marginTop: "13px" }}
                label="show in home"
                checked={isHomeContent}
                onChange={(v) => setIsHomeContent(v)}
              />
              <TextField
                type="number"
                label="priority in home"
                value={homePriority}
                onChange={(event) =>
                  setHomePriority(Number(event.target.value) || null)
                }
              />
              <AutocompleteAreaSelector
                style={{ marginTop: "13px" }}
                includeArea={includeArea}
                setIncludeArea={setIncludeArea}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card style={{ width: "100%" }}>
                <CardHeader title="Thumbnail" />
                <CardContent>
                  <span className="recommended-size">
                    Recommended image size: 320×260px
                  </span>
                  <MediaUploader
                    media={thumbnailImageUrl}
                    uploadPath={`locations/${props.location.id}`}
                    allowedTypes={[MediaType.Image]}
                    height="150px"
                    onChangeFunction={(i) => setThumbnailImageUrl(i)}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card style={{ width: "100%" }}>
                <CardHeader title="Image" />
                <CardContent>
                  <span className="recommended-size">
                    Recommended image size: 1240×400px
                  </span>
                  <MediaUploader
                    media={imageUrl}
                    uploadPath={`locations/${props.location.id}`}
                    allowedTypes={[MediaType.Image]}
                    height="150px"
                    onChangeFunction={(i) => setImageUrl(i)}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card style={{ width: "100%" }}>
                <CardHeader title="Video" />
                <CardContent>
                  <span className="recommended-size">
                    Recommended video size: 1240×400px
                  </span>
                  <MediaUploader
                    media={videoUrl}
                    uploadPath={`locations/${props.location.id}`}
                    allowedTypes={[MediaType.Video]}
                    width="100%"
                    onChangeFunction={(i) => setVideoUrl(i)}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className={"edit-button"}>
        <Button onClick={handleEditLocation}>Save</Button>
      </div>
    </>
  );

  function handleEditLocation() {
    if (!nameEn || !nameJa) {
      showNotification({
        message: "Please provide a Japanese and English name",
        severity: "error",
      });
      return;
    }
    editLocation({
      variables: {
        id: props.location.id,
        nameEn: nameEn,
        nameJa: nameJa,
        slug: slug,
        pageTitle: pageTitle,
        pageDescription: pageDescription,
        thumbnailImageUrl: thumbnailImageUrl,
        imageUrl: imageUrl,
        videoUrl: videoUrl,
        isHomeContent,
        homePriority,
        includeArea: includeArea.map((a) => a.id),
      },
      refetchQueries: ["GetLocationQuery", "LocationsQuery"],
    })
      .then(() => {
        showNotification({
          message: `Location was edited`,
          severity: "success",
        });
      })
      .catch(() => {
        if (isHomeContent && !homePriority) {
          showNotification({
            message: `Please input 'Priority in home' if you checked 'Show in home'`,
            severity: "error",
          });
        } else {
          showNotification({
            message: `Editing location failed!`,
            severity: "error",
          });
        }
      });
  }
}

const EDIT_LOCATION_MUTATION = gql`
  mutation EditLocationMutation(
    $id: ID!
    $nameEn: String!
    $nameJa: String!
    $slug: String
    $pageTitle: String
    $pageDescription: String
    $thumbnailImageUrl: String
    $imageUrl: String
    $videoUrl: String
    $isHomeContent: Boolean!
    $homePriority: Int
    $includeArea: [String]
  ) {
    editLocation(
      input: {
        id: $id
        nameEn: $nameEn
        nameJa: $nameJa
        slug: $slug
        pageTitle: $pageTitle
        pageDescription: $pageDescription
        thumbnailImageUrl: $thumbnailImageUrl
        imageUrl: $imageUrl
        videoUrl: $videoUrl
        isHomeContent: $isHomeContent
        homePriority: $homePriority
        includeArea: $includeArea
      }
    ) {
      id
    }
  }
`;
