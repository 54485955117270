import { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import Notification, {
  NotificationData,
} from "../../../components/Notification";
import { Template } from "../../../types";

export default function RestoreTemplateDialog(
  props: RestoreTemplateDialogProps
) {
  const [notification, setNotification] = useState<null | NotificationData>(
    null
  );
  const [restoreTemplate] = useMutation(RESTORE_TEMPLATE_MUTATION);
  const numPublishedPlans =
    props.template && props.template?.publishedPlans
      ? props.template?.publishedPlans?.length
      : 0;

  return (
    <>
      {props.template && (
        <Dialog open={props.open} onClose={props.onClose}>
          <DialogTitle>Restore Template</DialogTitle>
          <DialogContent>
            Are you sure you want to restore template{" "}
            <b>{props.template.name}</b>?
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              disabled={numPublishedPlans > 0}
              onClick={restoreTemplateHandler}
            >
              Restore
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {notification && (
        <Notification
          message={notification.message}
          severity={notification.severity}
          onClose={() => setNotification(null)}
        />
      )}
    </>
  );

  async function restoreTemplateHandler() {
    if (props.template) {
      const templateName = props.template.name;

      restoreTemplate({
        variables: { templateId: props.template.id },
        refetchQueries: ["TemplatesTrashQuery"],
      }).then((data: any) => {
        if (
          !data.data.restoreTemplate.success &&
          data.data.restoreTemplate.message
        ) {
          return setNotification({
            message: data.data.restoreTemplate.message,
            severity: "error",
          });
        }
        setNotification({
          message: `Template ${templateName} was restored`,
          severity: "success",
        });
      });
    }
    await props.refetch();
    props.onClose();
  }
}

const RESTORE_TEMPLATE_MUTATION = gql`
  mutation RestoreTemplateMutation($templateId: ID!) {
    restoreTemplate(input: { id: $templateId }) {
      success
      message
    }
  }
`;

interface RestoreTemplateDialogProps {
  open: boolean;
  onClose: () => void;
  refetch: () => void;
  template: Template | null;
}
